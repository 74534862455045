import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'

import {
  EMAIL_REGEX,
  PASSWORD_REGEX,
  PHONE_REGEX,
  POSTAL_NUMBER_REGEX,
} from 'src/utils/regex'
import { UpdateAddressRequestInterface } from 'src/interfaces/address'
import {
  updateAddress,
  updateAddressSkipSMS,
  createAddressDelivery,
} from 'src/useCase/address'
import { useAppDispatch, useAppSelector } from 'src/store'
import { PLAN_TYPE } from 'src/constants/app'

interface Props {
  planType: string
  isMedical: boolean
  showAddressFields: boolean
}

export const useAddressEntryForm = (props: Props) => {
  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.ui.app.authInfo)

  const isPrescription = props.planType === PLAN_TYPE.PRESCRIPTION

  const defaultValues: UpdateAddressRequestInterface = {
    postal_number: user?.postal_number || '',
    prefecture_id: user?.prefecture_id || '',
    city_id: user?.city_id || '',
    address: user?.address || '',
    building_name: user?.building_name || '',
    phone: user?.phone || '',
    email: user?.email || '',
    password: '',
    password_confirmation: '',
    postal_number_delivery: user?.postal_number_delivery || '',
    prefecture_id_delivery: user?.prefecture_id_delivery || '',
    city_id_delivery: user?.city_id_delivery || '',
    address_delivery: user?.address_delivery || '',
    building_name_delivery: user?.building_name_delivery || '',
  }

  const validationAddress = {
    postal_number: Yup.string()
      .required('入力してください。')
      .matches(POSTAL_NUMBER_REGEX, '郵便番号は半角数字7桁で入力してください')
      .max(7, '郵便番号は半角数字7桁で入力してください'),
    prefecture_id: Yup.string()
      .required('入力してください。')
      .max(30, '都道府県は30文字以下で入力してください'),
    city_id: Yup.string()
      .required('入力してください。')
      .max(30, '市区町村は30文字以下で入力してください'),
    address: Yup.string()
      .required('入力してください。')
      .max(30, '建物名/部屋番号は30文字以下で入力してください'),
    building_name: Yup.string().max(
      30,
      '建物名・部屋番号は30文字以下で入力してください',
    ),
    phone: Yup.string()
      .required('入力してください。') //電話番号を入力してください。
      .length(11, '携帯番号は11桁で入力してください。')
      .matches(PHONE_REGEX, '電話番号は０から始まる必要があります。'),
    email: Yup.string()
      .required('入力してください。') //必須項目が入力されていません
      .email('メールアドレスを正しく入力してください')
      .matches(EMAIL_REGEX, 'メールアドレスを正しく入力してください'),
  }

  const addressDeliveryValidation = {
    postal_number_delivery: Yup.string()
      .required('入力してください。')
      .matches(POSTAL_NUMBER_REGEX, '郵便番号は半角数字7桁で入力してください')
      .max(7, '郵便番号は半角数字7桁で入力してください'),
    prefecture_id_delivery: Yup.string()
      .required('入力してください。')
      .max(30, '都道府県は30文字以下で入力してください'),
    city_id_delivery: Yup.string()
      .required('入力してください。')
      .max(30, '市区町村は30文字以下で入力してください'),
    address_delivery: Yup.string()
      .required('入力してください。')
      .max(30, '建物名/部屋番号は30文字以下で入力してください'),
    building_name_delivery: Yup.string().max(
      30,
      '建物名・部屋番号は30文字以下で入力してください',
    ),
  }

  const validationEmail = {
    email: Yup.string()
      .required('入力してください。') //必須項目が入力されていません
      .email('メールアドレスを正しく入力してください')
      .matches(EMAIL_REGEX, 'メールアドレスを正しく入力してください'),
  }
  const validationPassword = {
    password: Yup.string()
      .required('入力してください。')
      .matches(
        PASSWORD_REGEX,
        'パスワードは 8文字以上で、文字と数字がそれぞれ1つ以上含まれている必要があります。',
      ),
    password_confirmation: Yup.string()
      .required('入力してください。')
      .matches(
        PASSWORD_REGEX,
        'パスワード（確認）は 8文字以上で、文字と数字がそれぞれ1つ以上含まれている必要があります。',
      )
      .oneOf([Yup.ref('password'), null], 'パスワード（確認）が一致しません。'),
  }

  const validationPrescriptionSchema = Yup.object().shape(
    !user?.hasPassword
      ? {
          ...validationAddress,
          ...validationEmail,
          ...(props.showAddressFields ? addressDeliveryValidation : {}),
          ...validationPassword,
        }
      : {
          ...validationAddress,
          ...(props.showAddressFields ? addressDeliveryValidation : {}),
          ...validationEmail,
        },
  )

  const validationAutomaticSchema = Yup.object().shape(
    !user?.hasPassword
      ? {
          ...validationAddress,
          ...(props.showAddressFields ? addressDeliveryValidation : {}),
          ...validationPassword,
        }
      : {
          ...validationAddress,
          ...(props.showAddressFields ? addressDeliveryValidation : {}),
        },
  )
  const validationMedicalSchema = Yup.object().shape(
    !user?.hasPassword
      ? {
          ...validationEmail,
          ...validationPassword,
        }
      : {
          ...validationEmail,
        },
  )

  const onSubmit = async (data: UpdateAddressRequestInterface) => {
    const mainAddress = {
      postal_number: data?.postal_number,
      prefecture_id: data?.prefecture_id,
      city_id: data?.city_id,
      address: data?.address,
      building_name: data?.building_name,
      phone: data?.phone,
      email: data?.email,
      password: data?.password,
      password_confirmation: data?.password_confirmation,
    }

    const deliveryAddress = props.showAddressFields
      ? {
          postal_number: data?.postal_number_delivery,
          city_id: data?.city_id_delivery,
          address: data?.address_delivery,
          building_name: data?.building_name_delivery,
          prefecture_id: data?.prefecture_id_delivery,
        }
      : {
          postal_number: data?.postal_number,
          prefecture_id: data?.prefecture_id,
          city_id: data?.city_id,
          address: data?.address,
          building_name: data?.building_name,
        }

    if (props.isMedical) {
      const submitDataMedical = {
        email: mainAddress.email,
        password: mainAddress.password,
        password_confirmation: mainAddress.password_confirmation,
        isIgnoreSms: user?.otp_verified_at ? false : true,
      }

      if (!mainAddress.password) {
        delete submitDataMedical.password
        delete submitDataMedical.password_confirmation
      }

      await dispatch(
        updateAddressSkipSMS({
          credentials: submitDataMedical,
          meta: form,
          planType: props.planType,
        }),
      )
    } else {
      const submitData = {
        ...mainAddress,
        isIgnoreSms: user?.phone && user?.otp_verified_at ? true : false,
      }

      if (!mainAddress.password) {
        delete submitData.password
        delete submitData.password_confirmation
      }

      const responseUpdateAddress = await dispatch(
        updateAddress({ credentials: submitData, meta: form }),
      )

      if (!_.get(responseUpdateAddress, 'error')) {
        await dispatch(
          createAddressDelivery({ credentials: deliveryAddress, meta: form }),
        )
      }
    }
  }

  const resultSchema = props.isMedical
    ? yupResolver(validationMedicalSchema)
    : isPrescription
    ? yupResolver(validationPrescriptionSchema)
    : yupResolver(validationAutomaticSchema)

  const form = useForm<UpdateAddressRequestInterface>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: resultSchema,
  })

  return {
    form,
    onSubmit,
  }
}
