import HTTPRequest from '../index'

const apiPathSelectedPlan = 'api/v2/users/plans/selected-from-lp'

class SelectedPlan extends HTTPRequest {
  getSelectedPlan(params: { user_treatment_id: number }) {
    return this.get(`${apiPathSelectedPlan}`, { params })
  }
}

export default new SelectedPlan()
