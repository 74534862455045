import React from 'react'
import styled from 'styled-components'
import { Form, Skeleton } from 'antd'
import ButtonCustom from 'src/components/form/Button'
import { usePaymentSquare } from './usePaymentSquare'

type Props = {
  onCharge: Function
  isZeroPayment?: boolean
  isSubscription?: boolean
}

const SquareForm = (props: Props) => {
  const { onCharge, isZeroPayment, isSubscription } = props
  const { form, onSubmit, loadingForm } = usePaymentSquare({ onCharge })
  const { handleSubmit, formState } = form

  const btnName = () => {
    if (isSubscription) {
      if (isZeroPayment) {
        return '購入する'
      } else {
        return '次へ進む'
      }
    } else {
      if (isZeroPayment) {
        return '次へ'
      } else {
        return 'お支払いをする'
      }
    }
  }

  return (
    <React.Fragment>
      <Wrapper>
        <StyleForm>
          {loadingForm && <Skeleton />}
          <div id="card-container"></div>
          <ButtonCustom
            onClick={handleSubmit(onSubmit)}
            loading={formState.isSubmitting}
            className="btn-default btn-chestnut-rose"
            htmlType="submit"
            name={btnName()}
            showIconRight
          />
        </StyleForm>
      </Wrapper>
    </React.Fragment>
  )
}

const Wrapper = styled.div`
  margin-top: 20px;
`

const StyleForm = styled(Form)`
  padding: 0 20px;

  & input {
    padding: 15px !important;
  }
  & .form-description {
    color: #323232;
    line-height: 24px;
    font-weight: 600;
    font-size: 13px;
  }
  & .btn-submit {
    font-size: 18px;
  }

  & label {
    font-size: 15px !important;
    font-weight: 700;
    display: block;
    margin-bottom: 10px;
  }
`

export default SquareForm
