import { createAsyncThunk } from '@reduxjs/toolkit'
import selectedPlanRequest from 'src/api/requests/selectedFromLp'
import selectedPlanActions from 'src/store/domain/selectedFromLp/selectedDetail'

export const getSelectedPlan = createAsyncThunk<
  any,
  { user_treatment_id: any }
>('get_selected_plan', async (params, { dispatch }) => {
  try {
    dispatch(selectedPlanActions.updateLoading(true))
    const res = await selectedPlanRequest.getSelectedPlan(params)
    if (res.data) {
      dispatch(selectedPlanActions.updateEntity(res.data.data))
    }
    dispatch(selectedPlanActions.updateLoading(false))
  } catch (error) {
    dispatch(selectedPlanActions.updateLoading(false))
  }
})
