import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UpdateAddressRequestInterface } from 'src/interfaces/address'
import { UserInterface } from 'src/interfaces/user'

export type AuthInfo = UserInterface

type State = {
  loading: boolean
  authInfo: AuthInfo | null
  cardAddress: UpdateAddressRequestInterface | null
}

const initialState: State = {
  loading: false,
  authInfo: null,
  cardAddress: null,
}

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'app',
  initialState,
  reducers: {
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    updateAuthInfo: (state, action: PayloadAction<AuthInfo | null>) => {
      state.authInfo = action.payload
    },
    updateCardAddress: (
      state,
      action: PayloadAction<UpdateAddressRequestInterface | null>,
    ) => {
      state.cardAddress = action.payload
    },
  },
})

export { reducer }
export default actions
