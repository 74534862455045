import { LocalStorage } from 'src/utils/LocalStorage'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import userTreatmentRequest from 'src/api/requests/userTreatment'
import { FormValues } from 'src/containers/MedicalSpecialty/components/useMedicalSpecialtyForm'
import history from 'src/libs/history'
import { PATH as PATH_AUTOMATIC_PLAN } from 'src/pages/automaticPlan'
import userTreatment from 'src/store/domain/myPage/userTreatment'
import detailUserTreatment from 'src/store/domain/myPage/detailUserTreatment'
import userTreatmentApi from 'src/api/requests/userTreatment'
import setNotificationActions from 'src/store/ui/notification'

export interface PostCreateUserTreatment {
  data: FormValues
  form: UseFormReturn<FormValues, any>
}

export type PayloadIndexUserTreatmentType = {
  with?: string
}

export const getUserTreatmentList = createAsyncThunk<
  any,
  PayloadIndexUserTreatmentType
>('get_user_treatment_list', async (params, { dispatch }) => {
  try {
    dispatch(userTreatment.updateLoading(true))
    dispatch(userTreatment.updateEntities([]))
    const res = await userTreatmentApi.index(params)

    dispatch(userTreatment.updateEntities(res.data.data))
    dispatch(userTreatment.updateLoading(false))
  } catch (error) {
    dispatch(userTreatment.updateLoading(false))
  }
})

export const createUserTreatment = createAsyncThunk<
  any,
  PostCreateUserTreatment
>('create_user_treatment', async ({ data, form }, { dispatch }) => {
  try {
    const response = await userTreatmentRequest.create(data)
    const userTreatment = response.data.data
    LocalStorage.setUserTreatmentId(String(userTreatment.id))
    LocalStorage.setMenuName(String(userTreatment.menu.name))

    history.push(
      PATH_AUTOMATIC_PLAN.QUESTIONS.replace(':id', String(data.menu_id)),
    )
  } catch (err: any) {
    form.setError('menu_id', {
      type: 'manual',
      message: '入力してください。',
    })
    dispatch(
      setNotificationActions.onNotification({
        type: 'error',
        message: err?.response?.data?.message,
      }),
    )
  }
})

export const getDetailUserTreatment = createAsyncThunk<any, any>(
  'get_user_treatment_detail',
  async ({ id, params }, { dispatch }) => {
    try {
      dispatch(detailUserTreatment.updateLoading(true))
      const res = await userTreatmentApi.show(id, params)

      dispatch(detailUserTreatment.updateEntity(res.data.data))
      dispatch(detailUserTreatment.updateLoading(false))
    } catch (error) {
      dispatch(detailUserTreatment.updateLoading(false))
    }
  },
)

export const updateUserTreatment = createAsyncThunk<any, any>(
  'update_user_treatmen',
  async ({ id, data }, { dispatch }) => {
    try {
      dispatch(detailUserTreatment.updateLoading(true))
      const res = await userTreatmentApi.update(id, data)

      dispatch(detailUserTreatment.updateEntity(res.data.data))
      dispatch(detailUserTreatment.updateLoading(false))
    } catch (error) {
      dispatch(detailUserTreatment.updateLoading(false))
    }
  },
)

export const updateCallState = createAsyncThunk<any, any>(
  'update-call-state',
  async ({ id, state }, { dispatch }) => {
    try {
      dispatch(detailUserTreatment.updateLoading(true))
      const res = await userTreatmentApi.updateCallState(id, state)

      dispatch(detailUserTreatment.updateEntity(res.data.data))
      dispatch(detailUserTreatment.updateLoading(false))
    } catch (error) {
      dispatch(detailUserTreatment.updateLoading(false))
    }
  },
)

export const fetcherUserTreatmentFromMail = async (id, params) => {
  const userTreatment = (await userTreatmentApi.show(id, params)).data.data

  LocalStorage.setUserTreatmentId(userTreatment.id)
  LocalStorage.setMenuName(userTreatment.menu?.name)

  return userTreatment
}

export const cancelUserTreatment = async (id) => {
  const res = await userTreatmentApi.cancel(id)

  return res
}

export const getReservationUserTreatment = createAsyncThunk<any, any>(
  'get_user_treatment_detail',
  async ({ id, params }, { dispatch }) => {
    try {
      dispatch(detailUserTreatment.updateLoading(true))
      const res = await userTreatmentApi.show(id, params)

      dispatch(detailUserTreatment.updateEntity(res.data.data))
      dispatch(detailUserTreatment.updateLoading(false))
    } catch (error) {
      dispatch(detailUserTreatment.updateLoading(false))
    }
  },
)
