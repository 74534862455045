import { createSlice } from '@reduxjs/toolkit'

type State = {
  clinicId: string
  menuId: string
  planId: string
  from: string
}

const initialState: State = {
  clinicId: '',
  menuId: '',
  planId: '',
  from: '',
}

const { actions, reducer } = createSlice({
  name: 'choicesUser',
  initialState,
  reducers: {
    setChoicesData: (state, action) => {
      state.clinicId = action.payload.clinicId
      state.menuId = action.payload.menuId
      state.planId = action.payload.planId
      state.from = action.payload.from
    },
  },
})
export { reducer }
export default actions
