// src/store/domain/userTreatment/userTreatmentSlice.js
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedUserTreatmentId: null,
}

const { actions, reducer } = createSlice({
  name: 'userTreatment',
  initialState,
  reducers: {
    setSelectedUserTreatmentId(state, action) {
      state.selectedUserTreatmentId = action.payload
    },
    clearSelectedUserTreatmentId(state) {
      state.selectedUserTreatmentId = null
    },
  },
})

export { reducer }
export default actions
