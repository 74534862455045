import { combineReducers } from '@reduxjs/toolkit'

import additionalShampoo from './additionalShampoo'
import addressByPostalCode from './address'
import aga from './aga'
import automaticPlan from './automaticPlan'
import chat from './chat'
import choicesUser from './choicesUser'
import clinic from './clinic'
import doctor from './doctor'
import feedbacks from './feedback'
import myPage from './myPage'
import notification from './notification'
import orders from './orders'
import payment from './payment'
import plans from './plan'
import selectedFromLp from './selectedFromLp'
import selectedUserTreatmentId from './selectedUserTreatmentId'
import user from './user'
import video from './video'

export default combineReducers({
  aga,
  automaticPlan,
  plans,
  video,
  doctor,
  payment,
  chat,
  myPage,
  user,
  addressByPostalCode,
  feedbacks,
  notification,
  additionalShampoo,
  clinic,
  orders,
  choicesUser,
  selectedFromLp,
  selectedUserTreatmentId,
})
