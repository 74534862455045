import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  APP_SQ_ENDPOINT_JS,
  SQ_APPLICATION_ID,
  SQ_LOCATION_ID,
} from 'src/constants/env-constant'
import useScript from 'src/hook/useScript'
import { useAppDispatch, useAppSelector } from 'src/store'
import uiActions from 'src/store/ui/notification'
import { getAddressByPostalCode } from 'src/useCase/address'
import LocalStorage from 'src/utils/LocalStorage'

export type FormValues = {
  postal_code?: string
  city?: string
  state?: string
  region?: string
  address_lines?: string
  addressByPostalCode?: {
    address1?: string
    address2?: string
    address3?: string
  }
}

export const usePaymentSquare = ({ onCharge }: { onCharge: Function }) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.ui.app.authInfo)
  const pricePlanSelected = useAppSelector(
    (state) => state.domain.plans.planDetail.entity?.price_after,
  )
  const status = useScript(APP_SQ_ENDPOINT_JS)
  const [loadingForm, setLoadingForm] = useState<boolean>(false)
  const [card, setCard] = useState<any>(undefined)
  const [payments, setPayments] = useState<any>(undefined)

  const defaultValues: FormValues = {
    city: '',
    address_lines: '',
    postal_code: '',
    state: '',
    region: '',
  }

  const form = useForm<FormValues>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
  })

  useEffect(() => {
    const isPaying = !!LocalStorage.getLocalStorage('is_paying')
    setLoadingForm(isPaying)
    if (status === 'ready') {
      initPage()
    }
  }, [status])

  const initPage = async () => {
    setLoadingForm(true)

    const square = (window as any).Square
    if (square) {
      const payments = square.payments(SQ_APPLICATION_ID, SQ_LOCATION_ID)
      const customCard = await payments.card({
        style: {
          input: {
            color: '#000',
            backgroundColor: '#F1EFE7',
          },
          '.input-container': {
            borderColor: '#CFC392',
          },
          '.input-container.is-focus': {
            borderColor: '#CFC392',
          },
        },
      })
      await payments.setLocale('ja-JP')
      await customCard.attach('#card-container')

      setCard(customCard)
      setPayments(payments)
    }
    setLoadingForm(false)
  }

  const onSubmit = async (data: FormValues) => {
    try {
      const tokenResult = await card.tokenize()

      let addressResult: {
        address1?: string
        address2?: string
        address3?: string
      } = {}

      if (
        tokenResult.status === 'OK' &&
        tokenResult.details.billing.postalCode
      ) {
        addressResult = await dispatch(
          getAddressByPostalCode({
            postal_code: tokenResult.details.billing.postalCode,
            isPaymentPage: true,
          }),
        ).unwrap()
      }

      const resultCard = await payments.verifyBuyer(tokenResult.token, {
        intent: 'CHARGE_AND_STORE',
        amount: pricePlanSelected,
        currencyCode: 'JPY',
        billingContact: {
          addressLines: addressResult
            ? [Object.values(addressResult || {}).join('')]
            : [''],
          familyName: user?.family_name || '',
          givenName: user?.given_name || '',
          email: user?.email,
          countryCode: 'JP',
          phone: user?.phone,
          state:
            addressResult && addressResult.address1
              ? addressResult?.address1
              : '',
          city:
            addressResult && addressResult.address2
              ? addressResult?.address2
              : '',
          region:
            addressResult && addressResult.address3
              ? addressResult?.address3
              : '',
          postalCode:
            tokenResult.details.billing.postalCode &&
            tokenResult.details.billing.postalCode,
        },
      })

      LocalStorage.setLocalStorage('is_paying', true)

      onCharge({
        ...tokenResult,
        verification_token: resultCard.token,
        building_name: '',
        address: addressResult?.address3,
        state: addressResult?.address1,
        city: addressResult?.address2,
        postalCode: tokenResult.details.billing.postalCode,
      })
    } catch (error) {
      dispatch(
        uiActions.onNotification({
          type: 'error',
          message: '正確なカード情報を入力してください。',
        }),
      )
    }
  }

  return { form, onSubmit, loadingForm }
}
