import React, { useEffect } from 'react'
import { Navigate, Outlet, useSearchParams } from 'react-router-dom'
import { PATH } from 'src/pages/myPage'
import LocalStorage from 'src/utils/LocalStorage'
import { PATH as PATH_AUTH } from 'src/pages/auth'
import { useAppDispatch } from 'src/store'
import choicesUserAction from 'src/store/domain/choicesUser/details'

const RestrictLayout: React.FC = () => {
  const accessToken = LocalStorage.accessToken
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  const uuid = searchParams.get('uuid')
  const clinicId = searchParams.get('clinicId')
  const planId = searchParams.get('planId')
  const menuId = searchParams.get('menuId')
  const from = searchParams.get('from')

  useEffect(() => {
    if (uuid) {
      LocalStorage.setClinicUuid(uuid)
    }

    if (clinicId || planId || menuId || from) {
      dispatch(
        choicesUserAction.setChoicesData({
          clinicId: clinicId || '',
          menuId: menuId || '',
          planId: planId || '',
          from: from || '',
        }),
      )
    }
  }, [uuid, clinicId, planId, menuId, from, dispatch])

  if (accessToken) {
    if (from === 'LP') {
      return <Navigate to={`${PATH_AUTH.MEDICAL_SPECIALTY}`} />
    }
    return <Navigate to={`${PATH.OTHER_MENU}`} />
  }
  return <Outlet />
}

export default RestrictLayout
