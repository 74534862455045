import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'

import LPHeader from 'src/components/layout/Header/LPHeader'
import ShampooHeader from 'src/components/layout/Header/ShampooHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { PLAN_TYPE } from 'src/constants/app'
import { useAppDispatch } from 'src/store'
import choicesUserAction from 'src/store/domain/choicesUser/details'
import LocalStorage from 'src/utils/LocalStorage'
import UserInfoForm from './components/UserInfoForm'

const RegisterContainer: React.FC = (props: any) => {
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const flowName = searchParams.get('flow')
  const uuid = searchParams.get('uuid')

  const clinicId = searchParams.get('clinicId')
  const planId = searchParams.get('planId')
  const menuId = searchParams.get('menuId')
  const from = searchParams.get('from')

  useEffect(() => {
    if (uuid) {
      LocalStorage.setClinicUuid(uuid)
    }

    if (clinicId || planId || menuId || from) {
      dispatch(
        choicesUserAction.setChoicesData({
          clinicId: clinicId || '',
          menuId: menuId || '',
          planId: planId || '',
          from: from || '',
        }),
      )
    }
  }, [uuid, clinicId, planId, menuId, from, dispatch])

  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview-step register-form not-padding-p">
        {flowName === PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION ? (
          <ShampooHeader name="シャンプー定期購入手続き" className="top-70" />
        ) : (
          <StepsHeader
            step={10}
            stepActive={1}
            stepActiveName="個人情報入力"
            className="top-70"
          />
        )}
        <HeaderStyle>ご利用者情報入力</HeaderStyle>
        <UserInfoForm />
      </div>
    </>
  )
}

export default RegisterContainer
