import HTTPRequest from 'src/api'
import { FormValues } from 'src/containers/Payment/components/usePayment'
import { FormValues as CHANGE_CARD_POST } from 'src/containers/MyPage/PaymentInfo/components/usePaymentInfo'

const apiPath = 'api/users/square'
const apiPathOrderPayment = 'api/v2/users/orders/payments'
const apiPathCardAdress = 'api/v2/users/orders/card-address'

class SquarePayment extends HTTPRequest {
  charge(params: FormValues) {
    return this.post(`${apiPath}/charge`, params)
  }

  chargeAdditional(data) {
    return this.post(`${apiPath}/charge-additional`, data)
  }

  changeCard(params: CHANGE_CARD_POST) {
    return this.post(`${apiPath}/change-card`, params)
  }

  orderPayment(payload) {
    return this.post(`${apiPathOrderPayment}`, payload)
  }

  getInfoAddress() {
    return this.get(`${apiPathCardAdress}`)
  }
}

export default new SquarePayment()
