import { LocalStorage } from 'src/utils/LocalStorage'
import { PostOrderInterface } from './plan'
import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'

import squarePayReq from 'src/api/requests/squarePayment'
import squareAction from 'src/store/domain/payment/square'
import history from '../libs/history'
import { PATH } from '../pages/payment'
import { PATH as AUTOMATIC_PLAN_PATH } from '../pages/automaticPlan'
import { PLAN_TYPE } from 'src/constants/app'
import { USER_STATE } from 'src/enum/userStatus'
import { PATH as PATH_AUTOMATIC_PLAN } from 'src/pages/automaticPlan'
import { PATH as PATH_PAYMENT } from 'src/pages/payment'

export interface SquareChargeParams {
  source_id: string
  plan_type: string
  verification_token?: string
  address?: string
  prefecture_id?: string
  city_id?: string
  postal_number?: string
  building_name?: string
}

export interface orderChargeParams {
  orderParams: PostOrderInterface
  chargeParams: SquareChargeParams
}

export const squareCharge = createAsyncThunk<any, orderChargeParams>(
  'square_charge',
  async (params, { dispatch }) => {
    try {
      dispatch(squareAction.updateLoading(true))
      let userTreatmentId = LocalStorage.getUserTreatmentId()
      const res = await squarePayReq.orderPayment({
        ...params.chargeParams,
        ...params.orderParams,
        ...{ user_treatment_id: userTreatmentId },
      })
      LocalStorage.setUserTreatmentId(res.data.data.user_treatment.id)

      handlerRedirectAfterPayment(
        params.chargeParams.plan_type,
        res.data.data.user.new_state,
        res.data.data.user_treatment.state,
      )

      dispatch(squareAction.updateEntity(res.data.data.transaction_id))
      LocalStorage.setLocalStorage('is_paying', false)
      dispatch(squareAction.updateLoading(false))
    } catch (error) {
      const msg = _.get(error, 'response.data.message', '')
      const code = _.get(error, 'response.data.code', '')
      dispatch(squareAction.updateLoading(false))
      dispatch(squareAction.updateErrorMsg(msg))
      dispatch(squareAction.updateErrorCode(code))
      history.push(PATH.PAYMENT_ERROR)
    }
  },
)

const handlerRedirectAfterPayment = (
  planType,
  userState,
  userTreatmentState,
) => {
  if (planType === PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION) {
    if (userState === USER_STATE.REQUESTING_SMS_VERIFICATION) {
      return history.push(PATH_AUTOMATIC_PLAN.SMS_AUTH)
    }

    if (
      _.includes(
        [
          USER_STATE.IDENTITY_VERIFICATION_NOT_REGISTERED,
          USER_STATE.IDENTITY_CHECK,
          USER_STATE.IDENTIFICATION_OK,
          USER_STATE.IDENTIFICATION_NG,
        ],
        userState,
      )
    ) {
      return history.push(PATH_PAYMENT.PAYMENT_SUCCESS)
    }

    return history.push(AUTOMATIC_PLAN_PATH.ADDRESS_ENTRY, {
      plan_type: planType,
    })
  }

  if (_.includes([PLAN_TYPE.EXAMINATION, PLAN_TYPE.PRESCRIPTION], planType)) {
    // user registered info
    if (userState === USER_STATE.REQUESTING_SMS_VERIFICATION) {
      return history.push(PATH_AUTOMATIC_PLAN.SMS_AUTH)
    }

    // user verify otp success
    if (userState === USER_STATE.IDENTITY_VERIFICATION_NOT_REGISTERED) {
      return history.push(PATH_PAYMENT.PAYMENT_SUCCESS)
    }

    // user registered card
    if (
      _.includes(
        [USER_STATE.IDENTIFICATION_OK, USER_STATE.IDENTITY_CHECK],
        userState,
      )
    ) {
      return history.push(PATH_AUTOMATIC_PLAN.COMPLETE_IDENTIFICATION)
    }

    // user after payment is not register user info
    return history.push(AUTOMATIC_PLAN_PATH.ADDRESS_ENTRY, {
      plan_type: planType,
    })
  }
}
